@use "sass:color";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/aos/dist/aos.css";


$color-primary: #00838F !default; // Primary Color (Deep Cyan-Teal)
$color-secondary: #006B65 !default; // Secondary Color (Deep Teal)
$color-accent: #FFB74D !default; // Accent Color for CTAs (Warm Gold)
$color-success: #4CAF50 !default; // Accent for success and highlights (Fresh Green)
$color-error: #D32F2F !default; // Accent for errors/warnings (Deep Red)
$color-bg: #F8F9FA !default; // Background color (Very Light Gray)
$color-text: #1A1A1A !default; // Main text color (Near Black)
$color-secondary-text: #B0BEC5 !default; // Secondary text (Muted Gray)

/* ========================================
   Base Styles
=========================================== */
body {
  background-color: $color-bg;
  color: $color-text !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.legal {
  h1, h2, h3, h4, h5, h6 {
    color: $color-primary!important;
    font-weight: 300;
  }

  h1 {
    margin: 1rem 0;
  }

  h2 {
    font-size: 1.8rem !important;
  }

  h3 {
    font-size: 1.6rem !important;
  }

  h2, h3 {
    margin-top: 1.8rem;
  }
}


/* ========================================
   Header & Footer
=========================================== */
header,
footer {
  //background-color: $color-primary;
  color: $color-bg;
  // Preserve existing layout and spacing below
}

/* ========================================
   Links
=========================================== */
a {
  color: $color-primary;

  &:hover {
    //color: darken($color-primary, 10%);
    color: color.scale($color-primary, $lightness: -10%);
  }
}

.subscribe-button {
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 75%;
  }
}

/* ========================================
   Buttons & CTAs
=========================================== */
.btn {
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;

  &.cta-accent {
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-text; // High contrast against accent
  }

  &.cta-secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: $color-bg; // Ensures clear readability
  }

  &.cta-green {
    background-color: $color-success;
    border-color: $color-success;
    color: $color-bg;
  }

  &.cta-red {
    background-color: $color-error;
    border-color: $color-error;
    color: $color-bg;
  }
}

/* ========================================
   Jumbotron / Section Headers
=========================================== */
.jumbotron {
  background-color: $color-primary;
  color: $color-text;
  padding: 2rem 1rem;

}

/* ========================================
   Additional Element Updates
   (Preserving existing code below)
=========================================== */

/* -- Your other SCSS code remains intact, with color values updated where applicable -- */

:root {
  --primary-brand: #00838F; /* Updated primary color */
  --deep-teal: #006B65; /* Supporting deep color for contrast */
  --background-light: #F8F9FA;
  --text-primary: #1A1A1A;
  --text-secondary: #B0BEC5; /* Softer gray for subtle text */
  --cta-accent: #5FC6A2; /* Replacing orange with fresh green */
  --success-green: #2ECC71;
  --error-red: #E53935;
  --base: var(--primary-brand);

  --color-primary: #00838F;
  --color-secondary: #006B65;
  --color-accent: #FFB74D;
  --color-success: #4CAF50;
  --color-error: #D32F2F;
  --color-bg: #F8F9FA;
  --color-text: #1A1A1A;
  --color-secondary-text: #B0BEC5;
}

html {
  scroll-behavior: smooth;

  body {
    overflow-x: hidden;
  }
}

.cta-primary {
  background: var(--base);
}

.cta-secondary {
  background: transparent;
  border: 2px solid #0097A7; /* Matches new primary brand color */
  color: #ffffff;;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.cta-secondary:hover {
  background: #0097A7;
  color: white;
  transform: translateY(-3px);
}

/********** Common Styles **********/
h2 {
  font-size: 2.8rem !important;
  font-weight: 700;
}

img {
  display: block;
  max-width: 100%;
}

a,
button {
  cursor: pointer;
}

.jumbotron {
  margin-bottom: 0 !important;
}

.cta-accent {
  background: var(--color-accent); /* Slightly deeper cyan */
  color: white;
  font-weight: bold;
}

.cta-accent:hover {
  background: #00838F; /* Darker for hover effect */
  color: white;
}

.atlas-cta {
  border-radius: 22px !important;
  padding: 12px 30px !important;
  font-weight: 700;
  transition: .3s ease-in-out !important;
}

.atlas-cta:hover {
  text-decoration: none;
  transform: translateY(-5px);
}

.text-light {
  color: #F0F0F0; /* Soft light color instead of pure white */
}

.atlas-cta-wide {
  width: 100%;
}

.cta-green {
  background: var(--base);
  font-weight: 600 !important;
}

.cta-green:hover {
  color: #ffffff;
  background: var(--deep-teal);
}

.cta-blue {
  background: #192440;
  color: #ffffff;
}

.cta-blue:hover {
  color: #ffffff;
  background: #121a2e;
}

.cta-ghost {
  border: 2px solid #192440 !important;
  color: #192440;
}

.cta-ghost:hover {
  color: #ffffff;
  background: #121a2e;
}

/********** Banner **********/
#banner {
  background-size: cover;
  margin: 0;
  padding: 3rem;
  background: linear-gradient(135deg, var(--primary-brand), var(--deep-teal));
}

#banner.short {
  background: linear-gradient(180deg, var(--primary-brand) 0%, var(--deep-teal) 100%);
}


#email-section-wrapper {
  background: linear-gradient(135deg, #00838F, #006B65);

  /*background: linear-gradient(135deg, #2ecc71, #145a32);*/
  color: white;
  padding: 0;
}

.login-btn {
  background: #ffffff;
  padding: 0.4rem 1.2rem;
  border-radius: 0.3rem;
  display: inline-flex;
  font-weight: 600;
  border: 2px solid #0097A7;
  color: #0097A7;
}

.login-btn:hover {
  background: #0097A7;
  color: white;
}

/********** Feature Sections **********/
#feature-first {
  background: var(--background-light);
}

#feature-last {
  background: linear-gradient(0deg, #00838F 0%, #006B65 100%);
}

/********** Pricing Table **********/
#early-access {
  padding-top: 4rem !important;
  padding-bottom: 2rem !important;
}

#price-table__premium {
  background: #192440;
  color: #ffffff;
}

/********** Contact **********/
#contact {
  background-size: cover;
}

#contact ul li {
  padding: 3px 0;
}

/********** Copyright **********/
#copyright {
  background: #fff;
  padding: 1rem 3rem;
  color: inherit;
}

#copyright a {
  color: #333333;
}

/********** Responsive Design **********/
@media (max-width: 575px) {
  #banner h1 {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 7rem !important;
  }

  #banner:not(.mobile) {
    margin: 0;
    height: 100vh;
    padding: 3rem 1rem;

    .container header + div {
      height: 80vh;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .mrk-footer {
    max-height: 40px;
  }
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

main {
  min-height: 90vh;
}

@for $i from 1 through 20 {
  $width-percentage: $i * 5; // 5, 10, 15, ..., 100

  .w-#{$width-percentage} {
    width: $width-percentage * 1%;
  }
}

